import React, { useEffect, useState } from 'react'
import createActivityDetector from 'activity-detector'
import './App.scss'
import Lottie from "lottie-react";
import HomeScreen from './screens/HomeScreen/HomeScreen'
import handAnimation from './assets/handanimation.json'

const useIdle = (options) => {
  const [isIdle, setIsIdle] = useState(false)
  useEffect(() => {
    const activityDetector = createActivityDetector(options)
    activityDetector.on('idle', () => {
      setIsIdle(true)
    })
    activityDetector.on('active', () => {
      setIsIdle(false)
    })
  })
  return isIdle
}

const HandOverlay = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        zIndex: 9999,
      }}
    >
      <Lottie animationData={handAnimation} />;
    </div>
  )
}

const App = () => {
  const isIdle = useIdle({ timeToIdle: 30000, inactivityEvents: [] })
  return (
    <div className="main-container">
      {isIdle && <HandOverlay />}
      <HomeScreen />
    </div>
  )
}

export default App
