const data = [
  {
    id: 'category-1',
    subcategories: [
      {
        id: 'subcategory-1',
        title: 'Buitenmuur isoleren',
        subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
        content:
          'Een enkelsteens (onge\xefsoleerde) buitenmuur kun je aan de binnenzijde isoleren door middel van een voorzetwand met een houten stijl- en regelwerk. Voor een goede isolatiewaarde vul je de ruimte tussen en eventueel achter de stijlen met een zo dik mogelijke laag glaswol of steenwol.',
        attachment: 'https://www.youtube.com/embed/AdY0_oA0YuE',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/muur-isoleren-buitenkant',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-2',
        title: 'Geluidsisolatie aanbrengen',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'Geluidsisolerende platen op je muur monteren? Dat is een mooie manier om geluid buiten te houden en om zelf geen geluidsoverlast te veroorzaken. Geluidsoverlast ontstaat voornamelijk door contactgeluid via vaste verbindingen met de aangrenzende ruimte. Effectieve geluidsisolatie maak je met geluidsisolerende materialen en constructies.',
        attachment: 'geluidsisolatie.jpg',
        attachmentType: 'image',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/geluidsisolatie-muur-aanbrengen',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-3',
        title: 'Binnenmuur isoleren',
        subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
        content:
          'Je binnenmuur isoleren? Isoleren aan de binnenkant doe je in twee situaties: wanneer je een muur hebt tussen twee verwarmde ruimtes (dit gaat meestal om geluidsisolatie), of een muur tussen een onverwarmde en een verwarmde ruimte, zoals een inpandige berging of een aanbouwkeuken. In dit GAMMA stappenplan lees je hoe je een wand isoleert tussen een verwarmde en onverwarmde ruimte.',
        attachment: 'binnenmuurisoleren.jpg',
        attachmentType: 'image',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/muur-isoleren-van-binnenuit',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-4',
        title: 'Spouwmuur isolatie',
        subtitle: 'KLUSNIVEAU: KLUSSERVICE',
        content:
          'Je huis isoleren; goed om je wooncomfort te verhogen en \xf3\xf3k nog eens goed voor je portemonnee en het milieu. Je kunt je isolatieklus ook overlaten aan een vakman via GAMMA. Bepaalde isolatieklussen zal je moeten laten uitbesteden omdat vakkennis, specialistisch gereedschap en materiaal benodigd is. ',
        attachment: 'klusservice.jpg',
        attachmentType: 'image',
        buttons: [
          {
            id: 'button 1',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-5',
        title: 'Gevel impregneren',
        subtitle: 'KLUSNIVEAU: KLUSSERVICE',
        content:
          'Je huis isoleren; goed om je wooncomfort te verhogen en \xf3\xf3k nog eens goed voor je portemonnee en het milieu. Je kunt je isolatieklus ook overlaten aan een vakman via GAMMA. Bepaalde isolatieklussen zal je moeten laten uitbesteden omdat vakkennis, specialistisch gereedschap en materiaal benodigd is. ',
        attachment: 'klusservice.jpg',
        attachmentType: 'image',
        buttons: [
          {
            id: 'button 1',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
    ],
  },
  {
    id: 'category-2',
    subcategories: [
      {
        id: 'subcategory-6',
        title: 'Houten vloer isoleren',
        subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
        content:
          'Zelf je kruipruimte isoleren is een de makkelijkere en goedkopere manier om een vloer te isoleren dan je gehele vloer te vervangen voor een nieuwe ge\xefsoleerde vloer, of vloerisolatie aan te brengen op een bestaande vloer.',
        attachment: 'https://www.youtube.com/embed/IQJ3EhJnv3s',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/kruipruimte-isoleren',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-7',
        title: 'Bodem kruipruimte isoleren ',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'De bodem van je kruipruimte kun je isoleren met behulp van EPS parels. Een duurzame \xe9n effectieve oplossing voor een vochtige kruipruimte, waarmee je de kou beneden houdt. Volg ons stappenplan en klaar je isolatie klus.',
        attachment: 'https://www.youtube.com/embed/IQJ3EhJnv3s',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/kruipruimte-isoleren-bodemfolie',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-8',
        title: 'Betonnen vloer isoleren',
        subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
        content:
          'Een koude vloer voelt onbehaaglijk aan. Vloerisolatie verhoogt niet alleen het wooncomfort, het scheelt bovendien in de stookkosten. Een vloer die direct op zand gestort is, kun je alleen aan de bovenzijde isoleren. We beperken ons hier tot isolatie aan de onderzijde, vanuit de kruipruimte.',
        attachment: 'betonnenvloer.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/vloerisolatie-betonvloer-aanbrengen',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
    ],
  },
  {
    id: 'category-3',
    subcategories: [
      {
        id: 'subcategory-10',
        title: 'LEDverlichting',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'ledverlichting.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Bekijk het assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=ledverlichting&f_type_artikel=LED',
          },
        ],
      },
      {
        id: 'subcategory-11',
        title: 'Slimme verlichting',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'ledverlichting.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/l/verlichting/slimme-verlichting?redirect=true',
          },
          {
            id: 'button 2',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/smart-led',
          },
        ],
      },
      {
        id: 'subcategory-12',
        title: 'Bewegingssensor',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'bewegingsensoren.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Bekijk het assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=bewegingssensor',
          },
        ],
      },
      {
        id: 'subcategory-13',
        title: 'Energiemeter',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'energiemeter.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Bekijk het assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=energiemeter',
          },
        ],
      },
    ],
  },
  {
    id: 'category-4',
    subcategories: [
      {
        id: 'subcategory-14',
        title: 'Waterbesparende douchekop',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'douchekop.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=douchekop',
          },
          {
            id: 'button 2',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/thermostaatkraan-vervangen',
          },
        ],
      },
    ],
  },

  {
    id: 'category-5',
    subcategories: [
      {
        id: 'subcategory-16',
        title: 'schuin dak isoleren',
        subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
        content:
          'Een schuin dak isoleren? Je huis isoleren zorgt ervoor dat je minder energie verspilt en comfortabeler woont. Ook bespaar je op jaarbasis gemiddeld 700 euro door je schuine dak te isoleren. Een schuin dak isoleren doe je met glaswol of steenwol. Deze isolatiematerialen zijn verkrijgbaar in rollen of isolatieplaten.',
        attachment: 'https://www.youtube.com/embed/E-MI4FCPOYM',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/schuin-dak-isoleren',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },

      {
        id: 'subcategory-18',
        title: 'Houten plafond isoleren',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'https://www.youtube.com/embed/bjtIFzOuWiM',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/plafond-isoleren',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-19',
        title: 'Plat dak isoleren',
        subtitle: 'KLUSNIVEAU: ERVAREN KLUSSER',
        content:
          'Je dak isoleren levert meer comfort op en bespaart geld. Een plat dak is het eenvoudigst te isoleren vanaf de buitenkant, maar ook vanaf de binnenkant kun je goed isoleren. Het is belangrijk om vochtregulerende klimaatfolie en bijbehorend tape te gebruiken om vochtproblemen te voorkomen.',
        attachment: 'https://www.youtube.com/embed/qvE69YuNeQs',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/plat-dak-isoleren-binnenzijde',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
    ],
  },
  {
    id: 'category-6',
    subcategories: [
      {
        id: 'subcategory-20',
        title: 'Zonnepanelen',
        subtitle: 'KLUSNIVEAU: KLUSSERVICE',
        content:
          'Je dak isoleren levert meer comfort op en bespaart geld. Een plat dak is het eenvoudigst te isoleren vanaf de buitenkant, maar ook vanaf de binnenkant kun je goed isoleren. Het is belangrijk om vochtregulerende klimaatfolie en bijbehorend tape te gebruiken om vochtproblemen te voorkomen.',
        attachment: 'zonnepanelen.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/assortiment/zonnepanelen-inclusief-installatie?redirect=true',
          },
        ],
      },
    ],
  },
  {
    id: 'category-7',
    subcategories: [
      {
        id: 'subcategory-21',
        title: 'Slimme thermostaat',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Met een traditionele thermostaat stel je de temperatuur van je huis vanuit \xe9\xe9n punt in. Met een slimme thermostaat en slimme radiatorknoppen het je de mogelijkheid voor elke ruimte een aparte temperatuur geven, zo kun je besparen op energie. Ook hier kun je geofencing gebruiken om je huis via GPS te laten of je wel of niet thuis bent. Ben je onderweg naar huis, dan kun je op afstand je verwarming met je telefoon aan zetten.',
        attachment: 'slimmethermostaat.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Bekijk het assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=slimme%20thermostaat',
          },
        ],
      },
      {
        id: 'subcategory-22',
        title: 'Thermostatische radiatorknop',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Met een traditionele thermostaat stel je de temperatuur van je huis vanuit \xe9\xe9n punt in. Met een slimme thermostaat en slimme radiatorknoppen het je de mogelijkheid voor elke ruimte een aparte temperatuur geven, zo kun je besparen op energie. Ook hier kun je geofencing gebruiken om je huis via GPS te laten of je wel of niet thuis bent. Ben je onderweg naar huis, dan kun je op afstand je verwarming met je telefoon aan zetten.',
        attachment: 'thermostatische-radiatorknop.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Bekijk het assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=thermostatische%20radiatorkraan',
          },
        ],
      },
      {
        id: 'subcategory-23',
        title: 'Radiator ventilator',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Met slimme radiator ventilatoren kun je een hoop geld en energie besparen. De ventilatoren zorgen ervoor dat de ruimte al in de helft van de tijd op temperatuur is, waardoor je tot 30% energie bespaart. En dat in een handomdraai. Volg onderstaand stappenplan om ze eenvoudig zelf te installeren.',
        attachment: 'https://www.youtube.com/embed/oATPuqaT3mE',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=Radiator+ventilator',
          },
          {
            id: 'button 2',
            text: 'Stappenplan',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusadvies/a/slimme-radiator-ventilator-aansluiten',
          },
        ],
      },
      {
        id: 'subcategory-24',
        title: 'Thermostatische mengkraan',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'thermostatische-mengkraan.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Bekijk het assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=thermostatische+mengkraan',
          },
        ],
      },
    ],
  },
  {
    id: 'category-8',
    subcategories: [
      {
        id: 'subcategory-25',
        title: 'Regenton/Hydroblob',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'regenton.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=hydroblob',
          },
          {
            id: 'button 2',
            text: 'Stappenplan',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusadvies/a/drainage-met-hydroblobs',
          },
        ],
      },
      {
        id: 'subcategory-26',
        title: 'Slim bewateren',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'slim-bewateren.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=Slim+bewateren',
          },
          {
            id: 'button 2',
            text: 'Stappenplan',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/slim-bewateren',
          },
        ],
      },
      {
        id: 'subcategory-27',
        title: 'Compostbak',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'compost.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=compostbak',
          },
          {
            id: 'button 2',
            text: 'Stappenplan',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusadvies/a/compostbak-maken',
          },
        ],
      },
      {
        id: 'subcategory-28',
        title: 'Sensorverlichting',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        attachment: 'sensor.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Assortiment',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/assortiment/zoeken?text=sensor%20verlichting',
          },
          {
            id: 'button 2',
            text: 'Stappenplan',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusadvies/a/bewegingssensor-aansluiten',
          },
        ],
      },
    ],
  },
  {
    id: 'category-9',
    subcategories: [
      {
        id: 'subcategory-29',
        title: 'Glasisolatie',
        subtitle: 'KLUSNIVEAU: KLUSSERVICE',
        content:
          'Je huis isoleren; goed om je wooncomfort te verhogen en \xf3\xf3k nog eens goed voor je portemonnee en het milieu. Je kunt je isolatieklus ook overlaten aan een vakman via GAMMA. Bepaalde isolatieklussen zal je moeten laten uitbesteden omdat vakkennis, specialistisch gereedschap en materiaal benodigd is.',
        attachment: 'klusservice.jpg',
        attachmentType: 'Image',
        buttons: [
          {
            id: 'button 1',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren?redirect=true',
          },
        ],
      },
    ],
  },
  {
    id: 'category-10',
    subcategories: [
      {
        id: 'subcategory-30',
        title: 'Buisisolatie',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Je verwarmingsbuizen isoleren? Met leidingisolatie voorkom je onnodige warmteafgifte van CV-leidingen en warmwaterleidingen. Dat scheelt een hoop stookkosten. Let op: het is alleen zinvol om leidingen te isoleren die door onverwarmde ruimtes lopen. Verwarmde ruimtes worden vaak intensiever gebruikt, waardoor het rendement te laag is. Bovendien is het isolatiemateriaal niet zo mooi als het in zicht hangt.',
        attachment: 'https://www.youtube.com/embed/-VrzIxQZggM',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/verwarmingsbuizen-isoleren',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-31',
        title: 'Radiatorfolie',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Een radiator geeft ook aan de achterkant warmte af. Zeker bij een niet-ge\xefsoleerde borstwering (het muurgedeelte onder het raamkozijn) gaat dan veel warmte verloren. Dat is niet alleen zonde van de energie, maar ook van je geld. Dit probleem los je eenvoudig op met radiatorfolie.',
        attachment: 'https://www.youtube.com/embed/4UFu1_7aeq8',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/radiatorfolie-aanbrengen',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-32',
        title: 'Tochtband',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Tochtband aanbrengen bij kieren is een goedkope isolatietoepassing die je in eenvoudige stappen zelf kunt uitvoeren. Zo houd je de kou buiten en warmte binnen. Dit is niet alleen prettig voor je wooncomfort, maar ook zeker voor de energierekening. Doordat opgewekte warmte niet meer ontsnapt hoeft de verwarming minder hard te stoken en wordt gas bespaard. Veel duurzamer dus!',
        attachment: 'https://www.youtube.com/embed/XyPNye5f_C8',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/tochtband-aanbrengen',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-33',
        title: 'Tochtstrip',
        subtitle: 'KLUSNIVEAU: BEGINNENDE KLUSSER',
        content:
          'Tochtstrips zijn harde profielen gemaakt van kunststof of aluminium met een borstel of flexibele rubberen rand. Hiermee kan goed tocht worden geweerd bij ramen en deuren, waardoor het binnen minder koud is en de verwarming minder energie verbruikt. Geproduceerde warmte wordt dan namelijk goed binnengehouden.',
        attachment: 'tochtstrip.jpg',
        attachmentType: 'image',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/tochtband-aanbrengen',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-34',
        title: 'Naden kitten',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'Kit heb je nodig bij de meest uiteenlopende klussen. Met kit sluit je namelijk kieren en naden af. Bij bewegende vlakken maak je hiermee een elastische verbinding, in de badkamer zorgt kit voor een waterdichte afsluiting.',
        attachment: 'naden-kitten.jpg',
        attachmentType: 'image',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/hoe-kitten',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-35',
        title: 'Raamfolie/Thermocover',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'Heb je enkelglas of gewoon dubbelglas en wil je op een gemakkelijke, snelle en goedkope manier de isolatiewaarde van het glas verbeteren? Dan is het een goed idee om raamfolie aan te brengen! Warmte wordt dan beter in huis gehouden en er wordt bespaard op energiekosten.',
        attachment: 'raamfolie.jpg',
        attachmentType: 'image',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/raamfolie-aanbrengen',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
      {
        id: 'subcategory-36',
        title: 'Deurdranger',
        subtitle: 'KLUSNIVEAU: GEVORDERDE KLUSSER',
        content:
          'Deurdrangers vind je in diverse vormen en maten en kunnen geschikt zijn voor verschillende gewichten in deuren. Voor een buitendeur of een zware binnendeur heb je een deurdranger met een schaararm nodig. Deze deurdranger heeft een sterk schaarmechanisme.',
        attachment: 'https://www.youtube.com/embed/TPSjyzxn3b8',
        attachmentType: 'youtube',
        buttons: [
          {
            id: 'button 1',
            text: 'Stappenplan',
            color: 'white',
            background: '#69AA0A',
            link: 'https://www.gamma.nl/klusadvies/a/deurdranger-monteren',
          },
          {
            id: 'button 2',
            text: 'Klusservice',
            color: 'white',
            background: '#003878',
            link: 'https://www.gamma.nl/klusservice/laten-isoleren',
          },
        ],
      },
    ],
  },
]

export default data
