import React, {useState} from 'react';
import DetailHeader from "../../components/detail/DetailHeader/DetailHeader";
import DetailContent from "../../components/detail/DetailContent/DetailContent";
import DetailWebsite from "../../components/detail/DetailWebsite/DetailWebsite";

const DetailScreen = ({closeDetail, subcategory}) => {
    const [website, setWebsite] = useState(null);
    const [shouldClose, setShouldClose] = useState(false);
    const [showWebsite, setIsShowWebsite] = useState(false);

    const clickButton = (button) => {
        setWebsite(button.link);
        setIsShowWebsite(true);
    }

    const backToHomePage = () => {
        if (showWebsite) {
            setIsShowWebsite(false);
        } else {
            closetMySelf();
        }
    }

    const closetMySelf = () => {
        setShouldClose(true);
        setTimeout(() => {
            closeDetail();

        }, 500);
    }
    return <div className={`detail-page ${shouldClose ? 'slide-out' : 'slide-in'}`}>
        <DetailHeader close={backToHomePage}/>
        <div className={'detail-slider'} style={{transform: `translateX(${showWebsite ? -100 : 0}%)`}}>
            <div className={'detail-slider__item'}>
                <DetailContent clickButton={clickButton} subcategory={subcategory}/>
            </div>
            <div className={'detail-slider__item'}>
                <DetailWebsite url={website}/>
            </div>

        </div>
    </div>
}

export default DetailScreen;
