import React from 'react'
import leftArrow from '../../../assets/icons/left-arrow-arround.svg'
import qr from '../../../assets/images/qr.png'

const DetailHeader = ({ close }) => {
  return (
    <div className={'detail-page__header'}>
      <img
        src={leftArrow}
        alt="Back"
        className={'back-button'}
        onClick={close}
      />
      <div className={'detail-page__header-right'}>
        <div className={'text-container'}>
          <div className={'header-info'}>?</div>
          <p className={'header-text'}>versturen naar je telefoon?</p>
        </div>
        <div className={'qr-code__container'}>
          <img src={qr} width={100} height={100} alt="" />
        </div>
      </div>
    </div>
  )
}

export default DetailHeader
