import React from 'react';

const DetailContent = ({clickButton, subcategory}) => {


    return (
      <div className={'detail-content'}>
        <h1 className={'detail-content__header'}>{subcategory.title}</h1>
        {subcategory.subtitle && (
          <div className={'detail-content__label'}>{subcategory.subtitle}</div>
        )}

        <p>{subcategory.content}</p>
        {subcategory.attachmentType === 'youtube' ? (
          <iframe
            width="100%"
            height="315"
            src={`${subcategory.attachment}?rel=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <img
            src={require(`../../../assets/images/${subcategory.attachment}`).default}
            alt=""
            width={'100%'}
          />
        )}

        <div className="action-container">
          {subcategory.buttons.map((button) => (
            <button
              key={button.id}
              className={'button'}
              style={{ background: button.background, color: button.color }}
              onClick={() => clickButton(button)}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>
    )
}

export default DetailContent;
