import React from 'react'

export default function LineAnimation() {
    return (
        <div className='line-animation'>
            <svg className='svg-animation' viewBox='0 0 1280 360'>
                <g>
                    <line
                        className='path'
                        fill='none'
                        id='svg_5'
                        stroke='#003878'
                        strokeDasharray='null'
                        strokeLinecap='null'
                        strokeLinejoin='round'
                        strokeWidth='8'
                        x1='114.5'
                        x2='318'
                        y1='288.5'
                        y2='289.5'
                    />
                    <line
                        className='path1'
                        fill='none'
                        id='svg_6'
                        stroke='#003878'
                        strokeDasharray='null'
                        strokeLinecap='null'
                        strokeLinejoin='round'
                        strokeWidth='8'
                        x1='315.5'
                        x2='394.5'
                        y1='289.5'
                        y2='203.5'
                    />
                </g>
            </svg>
        </div>
    )
}
