import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/logo.svg'
import DetailScreen from '../DetailScreen/DetailScreen'
import HomeHouse from '../../components/home/HomeHouse/HomeHouse'
import GammaData from '../../constants/GammaData'
import HomeLegend from '../../components/home/HomeLegend/HomeLegend'
import LineAnimation from '../../components/home/LineAnimation/LineAnimation'

const HomeScreen = () => {
  const [isDetailPage, setIsDetailPage] = useState(false)
  const [scale, setScale] = useState(1)
  const [translateX, setTranslateX] = useState(0)
  const [translateY, setTranslateY] = useState(0)
  const [isVisibleLegend, setIsVisibleLegend] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedSubCategory, setSelectedSubcategory] = useState()
  const [isLineStarted, setLineStarted] = useState()
  const [isListAnimated, setListAnimated] = useState()
  const [wrapperHeight, setWrapperHeight] = useState(188)

  useEffect(() => {
    // Effect will run on change of selected category
    if (selectedCategory && selectedCategory.subcategories) {
      let h = 0
      // Loop over the categories
      // Depending on the title length, add a pre-defined value to the total height
      selectedCategory.subcategories.forEach((cat) => {
        h += cat.title.length > 20 ? 78 : 48
      })
      setWrapperHeight(h) //Set the total height in the local state
    }
  }, [selectedCategory])

  const openDetailPage = (subcategory) => {
    setSelectedSubcategory(subcategory)
    setIsDetailPage(true)
  }

  const closeDetailPage = () => {
    setIsDetailPage(false)
    setSelectedSubcategory(null)
    resetZoom()
  }

  const tapPoint = (data) => {
    const x = (data.x * 980) / 2220
    const y = (data.y * 605.02) / 1371

    let _translateX = 0
    let _translateY = 0

    if (x < 300) {
      _translateX = 300 - x
    } else {
      _translateX = -(x - 300)
    }

    if (y < 250) {
      _translateY = 250 - y
    } else {
      _translateY = -(y - 250)
    }

    setTranslateX(_translateX)
    setTranslateY(_translateY)

    setScale(2)
    const category = GammaData.find((category) => category.id === data.id)
    setSelectedCategory(category)

    if (category !== selectedCategory) {
      setLineStarted(false)
      setIsVisibleLegend(false)
      setListAnimated(false)
    }

    setTimeout(() => {
      setLineStarted(true)
    }, 1200)

    setTimeout(() => {
      setIsVisibleLegend(true)
    }, 2000)
    setTimeout(() => {
      setListAnimated(true)
    }, 2500)
  }

  const closeLegend = () => {
    resetZoom()
    setSelectedCategory(null)
    setListAnimated(false)
  }

  const resetZoom = () => {
    setScale(1)
    setTranslateX(0)
    setTranslateY(0)
    setIsVisibleLegend(false)
    setLineStarted(false)
  }

  return (
    <div className="home-screen">
      <div className="home-screen__header">
        <img src={logo} alt={'logo'} />
      </div>

      <h1 className="home-screen__title">WAT WIL JE ISOLEREN?</h1>
      <p className="home-screen__subtitle">
        tik op het gedeelte waar jij je huis wil verduurzamen
      </p>

      <div className="home-screen__house">
        <div style={{ zIndex: 3 }}>
          {isLineStarted && <LineAnimation />}
          {isVisibleLegend && (
            <div
              className={'legend-container'}
              style={{
                height: selectedCategory ? `${wrapperHeight + 20}px` : 0,
              }}
            >
              <div className={'legend-close'} onClick={closeLegend} />
              <ul>
                {selectedCategory &&
                  isListAnimated &&
                  selectedCategory.subcategories.map((subcategory, index) => {
                    return (
                      <li
                        className="list-item"
                        key={index}
                        style={{ animationDelay: `${index * 0.2}s` }}
                        onClick={() => openDetailPage(subcategory)}
                      >
                        <span className="dot"></span>
                        {subcategory.title}
                      </li>
                    )
                  })}
              </ul>
            </div>
          )}
        </div>
        {selectedCategory && (
          <div
            className="click-out"
            style={{
              position: 'absolute',
              height: '100vh',
              width: '100vw',
              top: 0,
              left: 0,
              zIndex: 2,
            }}
            onClick={closeLegend}
          ></div>
        )}

        <div
          style={{
            transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
          }}
          className="home-house-ct"
        >
          <HomeHouse onTapPoint={tapPoint} />
        </div>
      </div>
      <HomeLegend />

      {isDetailPage && (
        <DetailScreen
          closeDetail={closeDetailPage}
          subcategory={selectedSubCategory}
        />
      )}
    </div>
  )
}

export default HomeScreen
